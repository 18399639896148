import { useAuthTokenRefresher } from '@pearlchain/component-lib-common';
import { TokenListener } from '@pearlchain/stackbase-common';
import React from 'react'

interface Props {
    children: React.ReactChild
}

export default function TokenListenerAux(props:Props) {
    const token = useAuthTokenRefresher();

    if(token) {
        return (
            <div>{props.children}</div>
        )
    }
    else {
        return (
            <TokenListener>
                <div>{props.children}</div>
            </TokenListener>
        )
    }
}
