import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { History } from 'history';
import { push } from 'connected-react-router';
import { useAuthTokenRefresher, RequestBuilder } from '@pearlchain/component-lib-common';
import { Navigation } from '@pearlchain/component-lib-ui';
import { StoreState } from '../types/storeTypes';
import { getActiveDashboardUuid } from '../utils/selector/selectors';
import DashboardPageSplitPane from './DashboardPageSplitPane';
import { setActiveDashboard, } from '../actions/dashboard-actions';
import { getKeyAndValues } from '../utils/PropertiesUtil';
import { i18nSetup } from '@pearlchain/component-lib-common';
import { setLanguageInLocalStorage } from '../utils/translation/LanguageChooser';
import { SimpleStackNavigation, useProperties } from '@pearlchain/stackbase-common';

type Props = {
    dashboardUuid: string | undefined;
    history: History;
    push: typeof push;
    setActiveDashboard: typeof setActiveDashboard;
};
type EventData = {
    type: string;
    token: string | null;
}

function redirectToLoginPage(properties: {[key: string]: string}) {
    window.location.assign(properties?.urlLogin+ "#/?redirect=" + window.location.href);
}

function DashboardPage(props: Props) {
    const token = useAuthTokenRefresher();
    const url = props.history.location.search;
    const searchParams = new URLSearchParams(url);
    const uuid = searchParams.get('uuid');
    const rcp = searchParams.get('rcp') === "true" ? true : false
    const properties =  getKeyAndValues(searchParams);
    const urlProperties = useProperties();

    const [isOnIframe, setIsOnIframe] = useState(false)

    useEffect(() => {
        setLanguageInLocalStorage(window.location.href)
        i18nSetup();
    }, []);

    useEffect(() => {
        if(uuid){
            props.setActiveDashboard(uuid);
        }
    }, [uuid]);

    useEffect(() => {
        var url_string = window.location.href
        var searchParams = new URLSearchParams(url_string);
        var hasToken = searchParams.get("auth-token");
        if(hasToken){
            window.localStorage.setItem('auth-token',hasToken)
            setIsOnIframe(true)
        }

        if (token == null) {
            // redirect the user to the login page if the token is absent
            if(!hasToken){
                redirectToLoginPage(urlProperties);
            }
        }
    }, [token, props.push]);

    useEffect(() => {
        function handler(response: Response): void {
            if (response.status === 403) {
                // redirect to the login page upon receiving an HTTP response with status 403
                redirectToLoginPage(urlProperties);
            }
        }

        const handlers = RequestBuilder.Opts.responseHandlers;
        handlers.push(handler);

        return () => {
            handlers.splice(handlers.indexOf(handler), 1);
        }
    }, [props.push]);

    if (token) {
        return (
            <div id="stack">
                {rcp ? null : <SimpleStackNavigation appTitle='Dashboards' appIcon="bolt" history={props.history}/>}  
                <div className="w-100 d-flex flex-column">
                    {!isOnIframe && <Navigation onSignOut={() => redirectToLoginPage(urlProperties)} menuItems={[]} loginPath="" languageCodes={["en"]}/>}
                    <DashboardPageSplitPane dashboardUuid={props.dashboardUuid} properties={properties}/>
                </div>
            </div>
        );
    } else {
        // return nothing. Wait for the login-page to be shown
        return null;
    }
}

export default connect((state: StoreState) => ({
    dashboardUuid: getActiveDashboardUuid(state)
}),{ push, setActiveDashboard })(DashboardPage);
