import { useAuthTokenRefresher } from '@pearlchain/component-lib-common';
import React, { useEffect } from 'react';
import DashboardContainer from './dashboard/DashboardContainer';
import HeaderItemProperties from './header/HeaderItemProperties';
import { shallowEqual, useSelector } from 'react-redux';
import { StoreState } from '../types/storeTypes';
import { AccessType } from '../types/responseTypes';
import { getDashboard } from '../utils/selector/selectors';


export default function ReadonlyDashboard(props:any) {
    const token = useAuthTokenRefresher();
    const url = props.history.location.search;
    const searchParams = new URLSearchParams(url);
    const uuid = searchParams.get('uuid');

    const { dashboardUuid, accessType, hasProperties } = useSelector(selector, shallowEqual);

    useEffect(() => {
        if (token == null) {
            // redirect the user to the login page if the token is absent
            props.history.replace('/login');
        }
    }, [token, props.push]);

    let properties: { [key: string]: unknown } = {}
    for(var key of searchParams.keys()) {
        if(key != 'uuid'){
            properties[key] = searchParams.get(key);
        }
    }

    function selector(state: StoreState) {
        const dashboardUuid = uuid
        let hasProperties = false;
        let accessType = AccessType.READ_ONLY;
    
        if (dashboardUuid) {
            const dashboard = getDashboard(state, dashboardUuid);
    
            if (dashboard) {
                hasProperties = dashboard.properties.length > 0;
                accessType = dashboard.accessType;
            }
        }
    
        return {
            dashboardUuid,
            accessType,
            hasProperties
        };
    }
    

    return (
        <div className='vh-100' >
            <div className='header-filter'>
                {dashboardUuid && hasProperties && <HeaderItemProperties/>}
            </div>
            <DashboardContainer
                splitSize={0}
                dashboardUuid={uuid as string}
                rootParent={undefined}
                parentProperties={properties}
                readonly={true}
            />
        </div>
    )
}
