import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Column as ColumnReactPG } from '@pearlchain/component-lib-react-pg';
import { SortColumn } from 'core/dashboard/components/widgets/singlesource/view/SingleSourceWidgetGrid';
import { jexl } from 'core/dashboard/evaluator/jexl';
import { ActionConfig, ActionHandler } from 'core/dashboard/types/actionHandlerTypes';
import { WidgetActions } from 'core/dashboard/types/widgetTypes';
import { NewChangeType, RowActionType } from 'core/dashboard/components/widgets/singlesource/view/SingleSourcePager';

export type ReactTableColumnType = {
    Header: string;
    accessor: string;
    format: unknown;
    Cell: (value: any) => JSX.Element;
}

/**
 * Cast Power-Grid columns to react table columns
 * @param  t translation function
 * @param {ColumnReactPG[]} columns Columns in PG type
 * @param {{[key: string]: unknown}} context Has the values of the dashboards properties
 * @param {ActionHandle} actionHandler  Will have all the functions for the action buttons
 */
export function getTableColumns(
    t: any,
    columns: ColumnReactPG[],
    context: {[key: string]: unknown},
    data: {[key: string]: unknown}[],
    actions: WidgetActions, 
    setNewChange: (el: NewChangeType) => void,
    setRowId: (el: RowActionType) => void): ReactTableColumnType[]{
    var cellValue = "";
    return columns.map((column) => {
        if(column.format){
            //If a column contains an action. It can be a drilldown action
            if((column.format as any).action){
                const action = (column.format as any).action;
                return handleAction(data, column, action, setRowId)
            }

            //If the column contains a format
            if((column.format as any).editable) {
                return {Header: column.title, accessor: column._key, format: column.format, Cell: (value: any) => {
                    let val = typeof value.value === "boolean" ? +value.value : value.value
                    const [cellValue, setValue] = useState((val === undefined || val === null) ? "" : val)

                    function handleChange(index:any,column:any,newValue:any){
                        setNewChange({index,column,newValue})
                    }

                    useEffect(() => {
                        if(val !== undefined || val !== null){
                            setValue(val)
                        }
                    }, [val])

                    //In case of a boolean
                    if(typeof value.value === "boolean"){
                        return (
                            <input
                                className="w-100"
                                value={cellValue}
                                onChange={e => setValue(e.target.value)}
                                onBlur={() => handleChange(value.row.id, column._key, cellValue)}
                            />
                        )
                    }

                    return (
                            <input
                                className="w-100"
                                value={cellValue}
                                onChange={e => setValue(e.target.value)}
                                onBlur={() => handleChange(value.row.id, column._key, cellValue)}
                            />
                    )
                }}
            }

            //If the column contains a format
            if((column.format as any).format) {

                //If the column contains a format but not conditions
                if(JSON.stringify((column.format as any).format.format ) !== '{}'){
                    const format = (column.format as any).format.format;
                    return {Header: column.title, accessor: column._key, width: getColumnWidth(data, column._key, column.title), format: column.format , Cell: (value: any) => {
                        var styles: { [key: string]: string; } = {};

                        for (let aux in format) {
                            styles[aux] = format[aux].value
                        }

                        //value.value is the actual value from the cell
                        cellValue = value.value;
                        //If the styles contains the translate option
                        if(styles['translate']){
                            cellValue = t(value.value)
                        }
                        //if the column contains a default value. This can be set on the column configuration
                        if((column.format as any).value){
                            cellValue = (column.format as any).value;
                        }

                        return (
                            <div className="h-100 d-flex align-items-center p-2 text-nowrap" style={styles}>
                                <span className="cell-over">{cellValue}</span>
                            </div>
                        )
                    }}
                }

                //If the column contains conditions
                if((column.format as any).format.conditions.length){
                    const conditions = (column.format as any).format.conditions;
                    return {Header: column.title, accessor: column._key, width: getColumnWidth(data, column._key, column.title), format: column.format, Cell: (value: any) => {
                        for(let condition of conditions){
                            context['row'] = value.row.original
                            let evaluation = null;
                            try {
                                evaluation = jexl.evalSync(condition.condition, {row: value.row.original});
                            } catch (error) {
                                if(value.row.id == "0"){
                                    actions.show("error", "Error","There was an error on your condition. Please review it again");
                                }
                            }
                             

                            var styles: { [key: string]: string; } = {};
                            for (let aux in condition.format) {
                                styles[aux] = condition.format[aux].value
                            }

                            //value.value is the actual value from the cell
                            cellValue = value.value;
                            //If the styles contains the translate option
                            if(styles['translate']){
                                cellValue = t(value.value)
                            }

                            //if the column contains a default value(inside a condition)
                            if(styles['value']){
                                if(styles['value'].includes("row.")){
                                    const row = {row: value.row.original}
                                    const ev = styles['value'].split('row.')[1]
                                    cellValue = row.row[ev]
                                }
                                else {
                                    cellValue = styles['value']
                                }
                            }

                            //if the column contains a default value. This can be set on the column configuration
                            if((column.format as any).value){
                                cellValue = (column.format as any).value;
                            }

                            if(evaluation){
                                return (
                                    <div className="h-100 d-flex align-items-center p-2 text-nowrap" style={styles}>
                                        <span className="cell-over">{cellValue}</span>
                                    </div>
                                )
                            }
                        }

                        //If the row doesnt math any conditions just return the value
                        return (
                            <div className="h-100 d-flex align-items-center p-2 text-nowrap">
                                <span className="cell-over">{(column.format as any).value || value.value}</span>
                            </div>
                        )
                        
                    }}
                }
            }
        }
        //Means that it is a normal column without formatting
        return {Header: column.title, accessor: column._key, format: column.format, Cell: (value: any) => {
            if(typeof value.value === "boolean"){
                return (
                    <div className="h-100 d-flex align-items-center p-2 text-nowrap">
                        <span className="cell-over">{+value.value}</span>
                    </div>
                )
            }

            if((column.format as any).value+"".includes("${")){
                let property = (column.format as any).value+""
                property = property.slice(2)
                property = property.slice(0,-1)
                const propertyValue = (context.properties as any)[property]
                return (
                    <div className="h-100 d-flex align-items-center p-2 text-nowrap">
                        <span className="cell-over">{propertyValue+""}</span>
                    </div>
                )
            }

            if(value.value === null || value.value === undefined){
                return (
                    <div className="h-100 d-flex align-items-center p-2 text-nowrap">
                        <span className="cell-over"></span>
                    </div>
                )
            }

            return (
                <div className="h-100 d-flex align-items-center p-2 text-nowrap">
                    <span className="cell-over">{(column.format as any).value || value.value+""}</span>
                </div>
            )
        }}
    })
}

//Gets the column width by the content length (largest value shall determine the column width)
function getColumnWidth(rows: {[key: string]: any}, accessor: string, headerText: string) : number {
    const maxWidth = 5000
    const magicSpacing = 10
    const rowValues = rows.map((row: any) => (`${row[accessor]}` || ''))
    rowValues.push(headerText);
    var longestValue = rowValues.sort(
        function (a: string, b: string) {
            return b.length - a.length;
        }
    )[0];
    var width = Math.round(getTextWidth(longestValue, "bold 1rem Tahoma"))+1;
    return Math.min(maxWidth, (width+magicSpacing)) + 25;
}

//Originally I used String.length to determine the width of the column, but this only gives the number of characters.
//To get the actual pixel width needed, we put the text into a fake HTML element that auto adjusts width, and then ask
//that element what it's width is.
function getTextWidth(text: string, font: string): number {
    // re-use canvas object for better performance
    {/*// @ts-ignore */}
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
}

/**
 * The objective of this function is to convert the sortBy object that we get from react-table to our needed column type for our sort function
 * @param {id: string, desc: boolean} columns Columns in PG type
 */
export function getSortedColumns(sortBy: any): SortColumn[]{
    const key = sortBy.id;
    const direction = sortBy.desc ? "descending" : "ascending"
    return [{key, direction}]
}


/**
 * Handles all the action buttons on react-table
 */
function handleAction(data: {[key: string]: unknown}[], column: ColumnReactPG, action: ActionConfig, setRowId:(el:RowActionType)=> void){
    return {
        Header: column.title,
        accessor: column.title, 
        format: column.format,
        width: getColumnWidth(data, column._key, column.title),
        maxWidth: getColumnWidth(data, column._key, column.title),
        Cell: (value: any) => {
            //value.row.original contains all the info of the row
            return (
                <div className="h-100 d-flex align-items-center text-nowrap">
                    <Button
                        onClick={() => {
                            return setRowId({actionConfig:action, index: +value.row.id})}
                        }
                    >
                        <i className="fa fa-arrow-right"></i>
                    </Button>
                </div>
            )
        }   
    }
}

