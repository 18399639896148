import React from 'react'
import DashboardContainer from './dashboard/DashboardContainer';
import { StoreState } from '../types/storeTypes';
import { AccessType } from '../types/responseTypes';
import { getDashboard } from '../utils/selector/selectors';
import { shallowEqual, useSelector } from 'react-redux';
import HeaderItemProperties from './header/HeaderItemProperties';

export default function UuidDashboard() {
    const splitHash = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
    const { dashboardUuid, accessType, hasProperties } = useSelector(selector, shallowEqual);

    function selector(state: StoreState) {
        const dashboardUuid = splitHash
        let hasProperties = false;
        let accessType = AccessType.READ_ONLY;
    
        if (dashboardUuid) {
            const dashboard = getDashboard(state, dashboardUuid);
    
            if (dashboard) {
                hasProperties = dashboard.properties.length > 0;
                accessType = dashboard.accessType;
            }
        }
    
        return {
            dashboardUuid,
            accessType,
            hasProperties
        };
    }
    
    return (
        <div className='vh-100' >
            <div className='header-filter'>
                {dashboardUuid && hasProperties && <HeaderItemProperties/>}
            </div>
            
            <DashboardContainer
                splitSize={0}
                dashboardUuid={splitHash as string}
                rootParent={undefined}
                parentProperties={{}}
                readonly={true}
            />
        </div>
    )
}
