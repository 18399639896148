import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FastField } from 'formik';
import ConfigPanel from '../ConfigPanel';
import FormControlFieldComponent from './FormControlFieldComponent';
import { validateRequired } from 'core/dashboard/utils/validate/validators';
import FormFieldErrorFeedback from './FormFieldErrorFeedback';
import CheckboxFormField from './CheckboxFormField';
const options = ["Paging with 50 results/page (default)", "Max 200 results, no paging", "Max 1000 results, no paging"]

type Props = {
    name: string
}

export default function PaginationField(props: Props) {
  return (
    <ConfigPanel defaultExpanded header="Common">
        <Form.Group>
                <div className='mb-2'>
                    <Form.Label>Pagination</Form.Label>
                    <FastField name={props.name} as="select" component={FormControlFieldComponent}>
                        {options.map((option, i) => (
                            <option key={i} value={option}>
                                {option}
                            </option>
                        ))}
                    </FastField>
                    <FormFieldErrorFeedback name="pagination"/>
                </div>
                <div className='mt-3'>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <div className='w-25'>
                            <CheckboxFormField name="autoSize" label="Scaled Row Height" />
                        </div>
                    </OverlayTrigger>
                    </div>
                
            </Form.Group>
    </ConfigPanel>
  )
}

const tooltip = (
    <Tooltip id="tooltip">
        <p>If enabled, this will scale the row height, occupying the full grid height if you only have a few rows</p>
    </Tooltip>
  );
